import ApliClient from '../factory/ww-api';

const isLoggedIn = async (publicKey, botIdentity) => {
    const { status } = await ApliClient.post('deskTicketTags/login', {
        publicKey,
        botIdentity
    });
    return status === 200;
};

const updateSettings = async (botIdentity, botAccessKey, tenantId) => {
    const { status, data } = await ApliClient.post(
        'deskTicketTags/settings',
        {
            botIdentity,
            botAccessKey,
            tenantId
        }
    );
    return status === 200 && data;
};

export { isLoggedIn, updateSettings };
