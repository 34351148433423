/* eslint-disable */
import production from './appsettings.json';
import development from './appsettings.development.json';

let settings = {};

try {
    settings = process.env.NODE_ENV !== 'development' ? production : development;
} catch {
    settings = production;
}

export default settings;
