import React from 'react';
import ConnectTo from '../../store/connect';
import Header from './components/Header';
import EmojisCard from './components/EmojisCard';

const PAGE_TITLE = 'Emojis';
const PAGE_ICON = 'emoji';
const PLUGIN_HELP_PAGE_URL = 'https://bit.ly/ww-plugin-emojis';

const Home = () => {
    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={PAGE_TITLE}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, '_blank')}
            />
            <EmojisCard />
        </div>
    );
};

Home.propTypes = {};

const mapStateToProps = (state, props) => ({
    ...props
});

export default ConnectTo(mapStateToProps)(Home);
