/* eslint-disable */
import React, { useEffect } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Card from '../../components/Card';

import Header from './components/Header';
import { showToast } from '../../services/common-service';
import * as ApplicationService from '../../services/application-service';
import * as UserService from '../../services/user-service';
import wwApi from '../../factory/ww-api';
import * as BlipPortalToastTypes from '../../config/constants/blip-portal-toast-types';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/628fef83556c4bd38bca2e33d55c525f';

const Home = () => {
    const [openAIApiKey, setOpenAIApiKey] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    useEffect(async () => {
        const config = await ApplicationService.getBucketDocument('wwgpt3pk');
        if (config) {
            setOpenAIApiKey('*'.padEnd(12, '*'));
        }
    }, [])

    useEffect(() => {
        location.href = 'https://plugins-v2.whitewall.dev/plugins/gpt3.html'
    }, [])

    const handleSave = async () => {
        setLoading(true);

        const { name, shortName, tenantId } = await ApplicationService.getApplicationData();
        const { email } = await UserService.getLoggedUser()

        try {
            const { data: { publicKey } } = await wwApi.post('/activate', {
                botIdentifier: shortName,
                botDetails: {
                    tenantId,
                    name
                },
                pluginId: 'gpt3',
                user: email,
                configuration: {
                    apiKey: openAIApiKey
                }
            })

            await ApplicationService.setBucketDocument('wwgpt3pk', 'text/plain', publicKey);

            showToast({
                type: 'success',
                message: 'Salvo com sucesso'
            });
        } catch (err) {
            console.error(err);
            showToast({
                type: BlipPortalToastTypes.danger,
                message:
                    'Não foi instalar o plugin. Por favor, tente novamente mais tarde.'
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={'GPT-3 Powered by OpenAI'}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, BLANK)}
            />
            <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div className="w-50 mt3 mr2 center">
                    <Card>
                        <div className="flex flex-column">
                            <Input
                                name="openai-apikey"
                                label="API Key"
                                placeholder="sk-...68J6"
                                value={openAIApiKey}
                                onChange={(e) =>
                                    setOpenAIApiKey(e.target.value)
                                }
                            />
                            <p className="f6 mt2 dark-blue">
                                Disponivel em:&nbsp;
                                <a
                                    target="_blank"
                                    href="https://beta.openai.com/account/api-keys"
                                    rel="noreferrer"
                                >
                                    https://beta.openai.com/account/api-keys
                                </a>
                                .
                            </p>
                            <div className="flex justify-end">
                                <Button
                                    text={'Salvar'}
                                    loading={loading}
                                    disabled={!openAIApiKey || [...openAIApiKey].every((item) => item === '*')}
                                onClick={handleSave}
                                />
                            </div>

                            <br />

                            <p className="f5 mt0 mb3">
                                Após a ativação, instale a extensão no
                                navegador&nbsp;
                                <a
                                    target="_blank"
                                    href="https://chrome.google.com/webstore/detail/builder-addictions/nfdmafhaljeeonfglijopeoicnnpgleb"
                                    rel="noreferrer"
                                >
                                    clicando aqui
                                </a>
                                .
                            </p>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Home;

