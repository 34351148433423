const home = { name: 'Home', path: '/_home' };
const example_page = { name: 'Example Page', path: '/_example' };
const builderVariables = { name: 'Builder Variables', path: '/' };
const emojis = { name: 'Emojis', path: '/emojis' };
const checkout = { name: 'Checkout', path: '/checkout' };
const activeCampaign = {
    name: 'Active Campaign',
    path: '/activeCampaign',
    pathNoPayWall: '/open/activeCampaign'
};
const dynamics = {
    name: 'Dynamics',
    path: '/dynamics',
    pathNoPayWall: '/open/dynamics'
};
const od = { name: 'Ouvidor Digital', path: '/od' };
const deskContactEditor = {
    name: 'BLiP Desk Contact Editor',
    path: '/desk-contact-editor'
};
const deskMarkUnread = {
    name: 'BLiP Desk Mark as Unread',
    path: '/desk-mark-unread'
};
const deskMassTransfer = {
    name: 'BLiP Desk Mass Transfer',
    path: '/desk-mass-transfer'
};
const deskTicketTags = {
    name: 'BLiP Desk Ticket Tags',
    path: '/desk-ticket-tags'
};
const zendesk = { name: 'Zendesk', path: '/zendesk' };
const gpt3 = { name: 'GPT-3 Powered by OpenAI', path: '/gpt3' };
const fileUploader = { name: 'File Uploader', path: '/file-uploader' };

const bitrix = {
    name: 'Bitrix 24',
    path: '/bitrix'
};

const exactSpotter = {
    name: 'Exact Spotter - Exact Sales',
    path: '/exactSpotter'
};

export {
    home,
    example_page,
    builderVariables,
    emojis,
    checkout,
    activeCampaign,
    dynamics,
    od,
    deskContactEditor,
    deskMarkUnread,
    deskMassTransfer,
    deskTicketTags,
    zendesk,
    gpt3,
    bitrix,
    fileUploader,
    exactSpotter
};
