import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConnectTo from '../../../../store/connect';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

// import { withLoading } from '../../../../services/common-service';
// import * as ApplicationService from '../../../../services/application-service';

const ContextVariables = ({ appInfo, language, variables, dispatch }) => {
    const [apiKey, setApiKey] = useState('');

    return (
        <div className="w-70 center mv3">
            <Card className="min-h-18">
                <div className="overflow-auto w-100">
                    <div className="fl w-80">
                        <Input
                            name="search"
                            placeholder="Informe a API Key da sua empresa"
                            value={apiKey}
                            onClick={(e) => {
                                // eslint-disable-next-line no-console
                                console.log(e);
                                setApiKey(e.target.value);
                            }}
                        />
                    </div>

                    <div className="fr w-15">
                        <Button
                            text="Salvar"
                            variant="ghost"
                            arrow={false}
                            disabled={false}
                            onClick={(e) => {
                                // eslint-disable-next-line no-alert
                                alert(
                                    'Entre em contato com o Ouvidor Digital para ter acesso a uma API Key válida!'
                                );
                            }}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

ContextVariables.propTypes = {
    appInfo: PropTypes.object,
    language: PropTypes.string,
    variables: PropTypes.object,
    onClick: PropTypes.func,
    dispatch: PropTypes.func
};

const mapStateToProps = (state, props) => ({
    appInfo: state.application.appInfo,
    language: state.common.language,
    variables: state.application.contextVariables,
    ...props
});

export default ConnectTo(mapStateToProps)(ContextVariables);
