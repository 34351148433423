import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import Card from '../../components/Card';
import Button from '../../components/Button';
import ConnectTo from '../../store/connect';

import logo from '../../assets/images/svg/blip-balloon.svg';
import freemiumLogo from '../../assets/images/svg/freemium.png';
import Header from './components/Header';
import {
    getPluginDataAsync,
    setFreemiumSubscriptionAsync
} from '../../services/plugin-api-service';
import {
    withLoading,
    showToast,
    showModal
} from '../../services/common-service';
import EnvSettings from '../../config/index';
import * as ApplicationService from '../../services/application-service';
import { getLoggedUser } from '../../services/user-service';
import * as BlipPortalToastTypes from '../../config/constants/blip-portal-toast-types';

const PAGE_ICON = 'payment-card';
const BLANK = '_blank';

const Home = () => {
    const location = useLocation();
    // const history = useHistory();

    const [plugin, setPlugin] = useState({});
    const [appInfo, setAppInfo] = useState({});
    const apiUrl = EnvSettings.ww_api.url;

    useEffect(() => {
        const { search } = location;
        if (!!search) {
            const params = queryString.parse(search);
            const { isFreemium = false } = params;
            if (!params || !params.pluginId || !params.publicKey) {
                // redirect;
            }

            withLoading(async () => {
                // get appInfo
                const resultAppInfo =
                    await ApplicationService.getApplicationData();
                setAppInfo(resultAppInfo);
                // get plugin data
                const _plugin = await getPluginDataAsync(params.pluginId);
                setPlugin({
                    ..._plugin,
                    id: params.pluginId,
                    publicKey: params.publicKey,
                    _owner: {
                        isFreemium: isFreemium === 'true'
                    }
                });
            });
        }
        // eslint-disable-next-line
    }, []);

    const ownerId =
        appInfo && appInfo.shortName ? `${appInfo.shortName}@msging.net` : '';
    const ownerName = appInfo && appInfo.name ? appInfo.name : ownerId;

    const price = plugin && plugin.price ? plugin.price.unitAmount : '?';

    const subscribeFreemium = () => {
        withLoading(async () => {
            try {
                const loggedUser = (await getLoggedUser()) || {};

                await setFreemiumSubscriptionAsync(
                    ownerId,
                    plugin.id,
                    loggedUser.fullName || '',
                    loggedUser.email || '',
                    plugin.publicKey
                );

                showToast({
                    type: BlipPortalToastTypes.success,
                    message: 'Carregado com sucesso'
                });

                showModal(
                    'Teste grátis ativado',
                    'Recarregue sua tela para começar a utilizar o plugin'
                );
            } catch (err) {
                showToast({
                    type: BlipPortalToastTypes.danger,
                    message:
                        'Não foi possível iniciar o teste para essa aplicação'
                });
            }
        });
    };

    const isFreemium = plugin._owner && (plugin._owner.isFreemium || false);

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={`Pagamento - ${plugin.name}`}
                icon={PAGE_ICON}
                onClick={() =>
                    window.open(
                        plugin.repository_url || 'https://whitewall.dev',
                        BLANK
                    )
                }
            />
            <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <h5 className="f5 b mt3 mb2">{'ATENÇÃO'}</h5>
                <p className="tc">
                    O plugin <b>{plugin.name}</b> é um plugin pago. <br />
                    Este plugin é de responsabilidade da empresa{' '}
                    <b>
                        <a href="https://whitewall.dev">White Wall</a>
                    </b>{' '}
                    <br />
                    <br />
                    <u>
                        Ao comprar você concorda que a Take Blip não possui
                        responsabilidade pelo funcionamento deste plugin.
                    </u>
                    <br />
                    <br />
                    {`Clique no botão abaixo para realizar o pagamento e começar a utilizá-lo.👇`}
                </p>
                <div className="center mv3">
                    <div className="w-100 flex justify-around">
                        <Card className="mr3 min-h-18">
                            <div className="flex flex-column items-center justify-center">
                                <img
                                    src={plugin.imageUrl || logo}
                                    className="br-100 h4 w4 dib ba b--black-05 pa1 bg-black-05"
                                    title={'data.name'}
                                    alt={'data.name'}
                                />
                                <h1 className="f3 mb2 ttc">{`R$ ${price},00`}</h1>
                                <p className="f5 fw4 gray mt0 justify-center">
                                    {`Acesso total por 1 ano`}
                                </p>
                                <Button
                                    text={'COMPRAR'}
                                    icon="payment-card-cvc"
                                    variant="primary"
                                    onClick={() => {
                                        window.open(
                                            `${apiUrl}/api/plugins/${plugin.id}/checkout?pluginId=${plugin.id}&publicKey=${plugin.publicKey}&ownerId=${ownerId}&ownerName=${ownerName}`,
                                            BLANK
                                        );
                                        // TODO: Add a page to show how to refresh the page;
                                    }}
                                    data_testid="checkout-link"
                                />
                            </div>
                        </Card>
                        <Card className="mr3 min-h-18">
                            <div className="flex flex-column items-center justify-center">
                                <img
                                    src={freemiumLogo}
                                    className="br-100 h4 w4 dib ba b--black-05 pa1 bg-black-05"
                                    title={'data.name'}
                                    alt={'data.name'}
                                />
                                <h1 className="f3 mb2 ttc">{`TESTE DRIVE`}</h1>
                                <p className="f5 fw4 gray mt0 justify-center">
                                    {`Acesso grátis por 7 dias`}
                                </p>
                                <Button
                                    text={'QUERO TESTAR'}
                                    icon="like"
                                    variant="ghost"
                                    disabled={isFreemium}
                                    onClick={() => {
                                        if (!isFreemium) {
                                            subscribeFreemium();
                                        }
                                    }}
                                    data_testid="checkout-link"
                                />
                            </div>
                        </Card>
                    </div>
                    <h2 className="f6 fw4 gray mt1">
                        {`Reembolso gratuito em até 30 dias*`}
                    </h2>
                </div>
                <p className="tc">
                    <b>{`Após a confirmação do pagamento`}</b>
                    <br />
                    {`todos os usuários que possuem acesso a este bot poderão utilizar o plugin ${plugin.name}.`}
                    <br />
                </p>
                <a
                    className="f6 flex items-center blue no-underline underline-hover"
                    href="#0"
                    data-testid="exemple-one"
                    onClick={() => {
                        window.open(
                            'https://whitewall.dev/#contact-section',
                            BLANK
                        );
                    }}
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {`Dúvidas? Clique aqui para para outras condições de pagamento ou para saber mais informações sobre o processo de compra do plugin ${plugin.name}`}
                </a>

                <p
                    className={'gray'}
                >{`*Caso o cliente opte por cancelar a compra do plugin, em até 30 dias, a White Wall realizará o estorno do valor pago menos o valor da taxa da transação (4,99%)`}</p>
            </div>
        </div>
    );
};

Home.propTypes = {
    appInfo: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        ...props
    };
};

export default ConnectTo(mapStateToProps)(Home);
