import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';

const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/8aec1eef65894e3e8eff093cfe0f306e';

const Settings = ({ settings, onSave }) => {
    // const { t } = useTranslation();
    const [exactToken, setExactToken] = useState(settings.exactToken || '');

    useEffect(() => {
        // on settings update
        setExactToken(settings.exactToken || '');
    }, [settings]);

    return (
        <>
            <Card>
                <h4 className="f4 mt0 mb3">{`Configurações`}</h4>
                <p>
                    {`Preencha as informações abaixo de acordo com sua conta da Exact Spotter (Exact Sales) e depois clique no botão 'Salvar'`}
                </p>
                <a
                    className="f6 flex items-center blue no-underline underline-hover"
                    href="#0"
                    data-testid="exemple-one"
                    onClick={() => {
                        window.open(PLUGIN_HELP_PAGE_URL, BLANK);
                    }}
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {`O token deverá ser gerado no sistema Exact Spotter, em CONFIGURAÇÕES > INTEGRAÇÕES.
                    Clique aqui para saber onde encontrar essas informações em sua conta Exact Spotter (Exact Sales)`}
                </a>
                <br />
                <div className="mb3">
                    <Input
                        name="exactToken"
                        label="Token da sua empresa na Exact Sales (token_exact)"
                        placeholder="ex.: zb9bctpe0z2qt2xa"
                        value={exactToken}
                        onChange={(e) => setExactToken(e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-center">
                    <div className="ml3">
                        <Button
                            text={'SALVAR'}
                            icon="save-disk"
                            variant="primary"
                            // disabled={!url || !key}
                            onClick={() => {
                                onSave(exactToken);
                            }}
                            disabled={!exactToken}
                            data_testid="repository-link"
                        />
                    </div>
                </div>
                <br />
            </Card>
        </>
    );
};

Settings.propTypes = {
    settings: PropTypes.object,
    onSave: PropTypes.func
};

export default Settings;
