const LEAD_VARIABLES = [
    {
        key: 'TITLE',
        description: `Lead's Title`,
        isRequired: false
    },
    {
        key: 'NAME',
        description: `Lead's Name`,
        isRequired: false
    },
    {
        key: 'COMPANY_NAME',
        description: `Company's name associated with Lead`,
        isRequired: false
    },
    {
        key: 'CONTACT_NAME',
        description: `Contact's name associated with Lead`,
        isRequired: false
    },
    {
        key: 'COMPANY_TITLE',
        description: `Lead's Company Title`,
        isRequired: false
    },
    {
        key: 'PHONE',
        description: `PhoneNumber associated to Contact or Company entity`,
        isRequired: false
    },
    {
        key: 'WEB',
        description: `Web Address  associated to Contact or Company entity`,
        isRequired: false
    },
    {
        key: 'EMAIL',
        description: `Email associated to Contact or Company entity`,
        isRequired: false
    },
    {
        key: 'ADDRESS',
        description: `Lead's Address`,
        isRequired: false
    },
    {
        key: 'ADDRESS_CITY',
        description: `Lead's City`,
        isRequired: false
    },
    {
        key: 'UTM_SOURCE',
        description: `Lead's UTM_SOURCE`,
        isRequired: false
    },
    {
        key: 'UTM_MEDIUM',
        description: `Lead's UTM_MEDIUM`,
        isRequired: false
    },
    {
        key: 'UTM_CAMPAIGN',
        description: `Lead's UTM_CAMPAIGN`,
        isRequired: false
    },
    {
        key: 'UTM_CONTENT',
        description: `Lead's UTM_CONTENT`,
        isRequired: false
    },
    {
        key: 'UTM_TERM',
        description: `Lead's UTM_TERM`,
        isRequired: false
    },
    {
        key: 'COMPANY_ID',
        description: `Lead's Company Id`,
        isRequired: false
    },
    {
        key: 'CONTACT_ID',
        description: `Lead's Contact Id`,
        isRequired: false
    }
];

const CONTACT_VARIABLES = [
    {
        key: 'NAME',
        description: `Contact's Name`,
        isRequired: false
    },
    {
        key: 'PHONE',
        description: `Contact's PhoneNumber`,
        isRequired: false
    },
    {
        key: 'WEB',
        description: `Contact's Web Address`,
        isRequired: false
    },
    {
        key: 'EMAIL',
        description: `Contact's Email`,
        isRequired: false
    },
    {
        key: 'UTM_SOURCE',
        description: `Contact's UTM_SOURCE`,
        isRequired: false
    },
    {
        key: 'UTM_MEDIUM',
        description: `Contact's UTM_MEDIUM`,
        isRequired: false
    },
    {
        key: 'UTM_CAMPAIGN',
        description: `Contact's UTM_CAMPAIGN`,
        isRequired: false
    },
    {
        key: 'UTM_CONTENT',
        description: `Contact's UTM_CONTENT`,
        isRequired: false
    },
    {
        key: 'UTM_TERM',
        description: `Contact's UTM_TERM`,
        isRequired: false
    }
];

const COMPANY_VARIABLES = [
    {
        key: 'TITLE',
        description: `Company's Name`,
        isRequired: false
    },
    {
        key: 'PHONE',
        description: `Company's PhoneNumber`,
        isRequired: false
    },
    {
        key: 'WEB',
        description: `Company's Web Address`,
        isRequired: false
    },
    {
        key: 'EMAIL',
        description: `Company's Email`,
        isRequired: false
    },
    {
        key: 'UTM_SOURCE',
        description: `Company's UTM_SOURCE`,
        isRequired: false
    },
    {
        key: 'UTM_MEDIUM',
        description: `Company's UTM_MEDIUM`,
        isRequired: false
    },
    {
        key: 'UTM_CAMPAIGN',
        description: `Company's UTM_CAMPAIGN`,
        isRequired: false
    },
    {
        key: 'UTM_CONTENT',
        description: `Company's UTM_CONTENT`,
        isRequired: false
    },
    {
        key: 'UTM_TERM',
        description: `Company's UTM_TERM`,
        isRequired: false
    }
];

export { LEAD_VARIABLES, CONTACT_VARIABLES, COMPANY_VARIABLES };
