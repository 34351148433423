import ApliClient from '../factory/ww-api';

const getPluginDataAsync = async (pluginId) => {
    const { data } = await ApliClient.get(`api/plugins/${pluginId}`);
    return data;
};

const setFreemiumSubscriptionAsync = async (
    ownerId,
    pluginId,
    brokerName,
    brokerId,
    publicKey
) => {
    const { data } = await ApliClient.post(`api/freemium/`, {
        ownerId,
        pluginId,
        brokerName,
        brokerId,
        publicKey
    });
    return data;
};

const getSubscriptionAsync = async (publicKey) => {
    try {
        const config = {
            headers: {
                Authorization: publicKey
            }
        };
        const { data } = await ApliClient.get('api/subscription', config);
        return data;
    } catch (err) {
        return false;
    }
};

export {
    getPluginDataAsync,
    getSubscriptionAsync,
    setFreemiumSubscriptionAsync
};
