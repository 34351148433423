const LEAD_VARIABLES = [
    {
        key: 'name',
        description: `Nome do lead`,
        isRequired: true
    },
    {
        key: 'industry',
        description: `Nome do Mercado`,
        isRequired: false
    },
    {
        key: 'source',
        description: `Nome da Origem`,
        isRequired: false
    },
    {
        key: 'subSource',
        description: `Nome da Sub-origem`,
        isRequired: false
    },
    {
        key: 'sdrEmail',
        description: `Email do pv que vai ser responsavel pelo lead`,
        isRequired: false
    },
    {
        key: 'group',
        description: `Nome do grupo`,
        isRequired: false
    },
    {
        key: 'mktLink',
        description: `URL do marketing da Empresa`,
        isRequired: false
    },
    {
        key: 'ddiPhone',
        description: `Número DDI do Telefone da Empresa`,
        isRequired: false
    },
    {
        key: 'phone',
        description: `Telefone da Empresa`,
        isRequired: false
    },
    {
        key: 'ddiPhone2',
        description: `Número DDI do Telefone 2 da Empresa`,
        isRequired: false
    },
    {
        key: 'phone2',
        description: `Telefone 2 da Empresa`,
        isRequired: false
    },
    {
        key: 'website',
        description: `Website da Empresa`,
        isRequired: false
    },
    {
        key: 'leadProduct',
        description: `Descrição do produto oferecido pelo lead`,
        isRequired: false
    },
    {
        key: 'address',
        description: `Endereço da Empresa`,
        isRequired: false
    },
    {
        key: 'addressComplement',
        description: `Complemento do endereço da Empresa`,
        isRequired: false
    },
    {
        key: 'zipcode',
        description: `CEP ou Código da região`,
        isRequired: false
    },
    {
        key: 'city',
        description: `Nome da Cidade`,
        isRequired: false
    },
    {
        key: 'state',
        description: `Nome do Estado`,
        isRequired: false
    },
    {
        key: 'country',
        description: `Nome do País`,
        isRequired: false
    },
    {
        key: 'cpfcnpj',
        description: `CPF ou CNPJ do lead`,
        isRequired: false
    },
    {
        key: 'description',
        description: `Observações diversas acerca do lead`,
        isRequired: false
    }
];

export { LEAD_VARIABLES };
