import ApliClient from '../factory/ww-api';

const updateCustomFields = async (
    publicKey,
    tenantId,
    host,
    clientId,
    userName,
    password,
    blipToken
) => {
    const api = ApliClient;
    const config = {
        headers: {
            Authorization: publicKey
        }
    };
    const { data } = await api.post(
        'dynamics/settings',
        {
            tenantId,
            host,
            clientId,
            userName,
            password,
            blipToken
        },
        config
    );
    return data;
};

export { updateCustomFields };
