const set_application = 'setApplication';
const set_configuration = 'setConfiguration';
const set_context_variables = 'setContextVariables';
const set_bucket_document = 'setBucketDocument';

export {
    set_application,
    set_configuration,
    set_context_variables,
    set_bucket_document
};
