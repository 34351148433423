import React from 'react';
import ConnectTo from '../../store/connect';
import Header from './components/Header';
import ContextVariables from './components/ContextVariables';

const PAGE_TITLE = 'Builder Variables';
const PAGE_ICON = 'resources';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/5363c62c99e94c6c9648cf8ce295d0d6';

const Home = () => {
    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={PAGE_TITLE}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, '_blank')}
            />
            <ContextVariables />
        </div>
    );
};

Home.propTypes = {};

const mapStateToProps = (state, props) => ({
    ...props
});

export default ConnectTo(mapStateToProps)(Home);
