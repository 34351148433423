/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import ConnectTo from '../../store/connect';

import Header from './components/Header';
import Settings from './components/Settings';
import Variables from './components/Variables';

import { withLoading } from '../../services/common-service';
import * as ApplicationService from '../../services/application-service';
import * as DynamicsService from '../../services/dynamics-service';
import * as PluginAPIService from '../../services/plugin-api-service';
import * as ApplicationActions from '../../store/actions/application';
import { uuid } from '../../utils';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/933f0a086afc4d10aa0fbeef17f5a600';
const PLUGIN_ID = 'dynamics';
const SETTINGS_BUCKET_DOCUMENT_ID = 'ext:dynamics:settings';
const SETTINGS_TYPE = 'application/json';

const getWebhookUrl = (publicKey) => {
    return `https://southamerica-east1-ww-blip-plugins.cloudfunctions.net/dynamics/webhooks/${publicKey}`;
};

const defaultAppInfo = { applicationJson: { accessKey: '', identifier: '' } };

const Home = ({ appInfo, settings, dispatch }) => {
    const history = useHistory();
    // const { t } = useTranslation();
    let blipToken = '';

    if (appInfo && appInfo.applicationJson) {
        const { accessKey, identifier } =
            appInfo.applicationJson || defaultAppInfo.applicationJson;

        blipToken = btoa(`${identifier}:${atob(accessKey)}`);
    }

    useEffect(() => {
        getSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveNewPublicKeyAsync = async (publicKey) => {
        const newPublicKey = publicKey || uuid();
        const bucketResource = {
            tenantId: '',
            host: '',
            clientId: '',
            userName: '',
            password: '',
            fields: [],
            publicKey: newPublicKey
        };

        await ApplicationService.setBucketDocument(
            SETTINGS_BUCKET_DOCUMENT_ID,
            SETTINGS_TYPE,
            bucketResource
        );

        await dispatch(
            ApplicationActions.setBucketDocument(
                SETTINGS_BUCKET_DOCUMENT_ID,
                bucketResource
            )
        );
        return newPublicKey;
    };

    const redirectCheckoutPage = (publicKey, isFreemium = false) => {
        return history.push(
            `/checkout/?pluginId=${PLUGIN_ID}&publicKey=${publicKey}&isFreemium=${isFreemium}`
        );
    };

    const getSettings = () => {
        withLoading(async () => {
            let bucketResource;
            let subscription;
            try {
                const resultAppInfo =
                    await ApplicationService.getApplicationData();

                // Update appInfo
                await dispatch(
                    ApplicationActions.setApplication(resultAppInfo)
                );

                bucketResource = await ApplicationService.getBucketDocument(
                    SETTINGS_BUCKET_DOCUMENT_ID
                );

                let { publicKey } = bucketResource;

                if (!publicKey) {
                    console.log('Invalid Subscription');
                    // save a new publicKey
                    publicKey = await saveNewPublicKeyAsync();
                    // redirect to checkout
                    redirectCheckoutPage(publicKey);
                    return;
                }

                subscription = await PluginAPIService.getSubscriptionAsync(
                    publicKey
                );

                if (!subscription) {
                    // redirect to checkout
                    console.log('Invalid Subscription');
                    redirectCheckoutPage(publicKey);
                } else if (new Date(subscription.expiresIn) <= new Date()) {
                    console.log('Expired Subscription');
                    // clean webhooks

                    await ApplicationService.deleAnalyticsWebhooks(
                        getWebhookUrl(publicKey)
                    );
                    redirectCheckoutPage(publicKey, true);
                }
            } catch (err) {
                console.warn(`Bucket doesn't exists`);
                // save a new publicKey
                const publicKey = await saveNewPublicKeyAsync();
                // redirect to checkout
                redirectCheckoutPage(publicKey);
            }

            // Update props
            await dispatch(
                ApplicationActions.setBucketDocument(
                    SETTINGS_BUCKET_DOCUMENT_ID,
                    bucketResource
                )
            );
        });
    };

    const setSettings = (
        tenantId,
        host,
        clientId,
        userName,
        password,
        bToken
    ) => {
        withLoading(async () => {
            try {
                // Get customfields
                const { publicKey } = settings;
                const fields = await DynamicsService.updateCustomFields(
                    publicKey,
                    tenantId,
                    host,
                    clientId,
                    userName,
                    password,
                    bToken
                );
                const _settings = {
                    tenantId,
                    host,
                    clientId,
                    userName,
                    password,
                    fields,
                    publicKey,
                    blipToken: bToken
                };

                // Set Webhook
                await ApplicationService.addAnalyticsWebhooks(
                    getWebhookUrl(publicKey)
                );

                await ApplicationService.setBucketDocument(
                    SETTINGS_BUCKET_DOCUMENT_ID,
                    SETTINGS_TYPE,
                    _settings
                );

                await dispatch(
                    ApplicationActions.setBucketDocument(
                        SETTINGS_BUCKET_DOCUMENT_ID,
                        _settings
                    )
                );
            } catch (err) {
                console.warn('Bucket doesnt exists');
            }
        });
    };

    // eslint-disable-next-line prettier/prettier
    const hasValues =
        settings.tenantId &&
        settings.host &&
        settings.clientId &&
        settings.userName &&
        settings.password;

    const displayVariables = hasValues ? 'block' : 'none';
    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={'Dynamics'}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, BLANK)}
            />
            <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div className="w-50 mt3 mr2 center">
                    <Settings
                        settings={settings || {}}
                        onSave={(
                            tenantId,
                            host,
                            clientId,
                            userName,
                            password
                        ) =>
                            setSettings(
                                tenantId,
                                host,
                                clientId,
                                userName,
                                password,
                                blipToken
                            )
                        }
                    />
                </div>
                <br />
                <div
                    className="w-50 mt3 mr2 center"
                    style={{
                        display: `${displayVariables}`
                    }}
                >
                    <Variables customFields={settings.fields} />
                </div>
            </div>
        </div>
    );
};

Home.propTypes = {
    appInfo: PropTypes.object,
    settings: PropTypes.object,
    dispatch: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        appInfo: state.application.appInfo,
        language: state.common.language,
        buckets: state.application.buckets,
        settings: state.application.buckets[SETTINGS_BUCKET_DOCUMENT_ID] || {
            tenantId: '',
            host: '',
            clientId: '',
            userName: '',
            password: '',
            fields: [],
            publicKey: ''
        },
        ...props
    };
};

export default ConnectTo(mapStateToProps)(Home);
