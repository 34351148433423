/* eslint-disable no-console */
import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import ConnectTo from '../../store/connect';

import Header from './components/Header';
import Settings from './components/Settings';
import Variables from './components/Variables';

import { withLoading } from '../../services/common-service';
import * as ApplicationService from '../../services/application-service';
import * as ApplicationActions from '../../store/actions/application';
import * as BitrixService from '../../services/bitrix-service';

import { uuid } from '../../utils';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL = 'https://www.loom.com/share/78ab758aa57a47d687b60fd9548f0914';

const B24_SETTINGS_BUCKET_DOCUMENT_ID = 'ext:bitrix24:settings';
const B24_SETTINGS_TYPE = 'application/json';

const defaultAppInfo = { applicationJson: { accessKey: '', identifier: '' } };

const getWebhookUrl = (publicKey) => {
    return `https://southamerica-east1-ww-blip-plugins.cloudfunctions.net/bitrix/webhooks/${publicKey}`;
};

const Home = ({ appInfo, settings, dispatch }) => {
    // const history = useHistory();
    // const { t } = useTranslation();
    let blipToken = '';

    if (appInfo && appInfo.applicationJson) {
        const { accessKey, identifier } =
            appInfo.applicationJson || defaultAppInfo.applicationJson;

        blipToken = btoa(`${identifier}:${atob(accessKey)}`);
    }

    const ownerId =
        appInfo && appInfo.shortName ? `${appInfo.shortName}@msging.net` : '';
    const brokerName = appInfo && appInfo.name ? appInfo.name : ownerId;
    const brokerId =
        appInfo && appInfo.emailOwner ? appInfo.emailOwner : ownerId;

    useEffect(() => {
        getSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSettings = () => {
        withLoading(async () => {
            let bucketResource;
            try {
                const resultAppInfo =
                    await ApplicationService.getApplicationData();

                // Update appInfo
                await dispatch(
                    ApplicationActions.setApplication(resultAppInfo)
                );

                bucketResource = await ApplicationService.getBucketDocument(
                    B24_SETTINGS_BUCKET_DOCUMENT_ID
                );

                const { publicKey } = bucketResource;

                if (!publicKey) {
                    console.log('Invalid Subscription');
                    return;
                }
            } catch (err) {
                console.warn(`Bucket doesn't exists`);
                return;
            }

            // Update props
            await dispatch(
                ApplicationActions.setBucketDocument(
                    B24_SETTINGS_BUCKET_DOCUMENT_ID,
                    bucketResource
                )
            );
        });
    };

    const setSettings = (webhookUrl) => {
        withLoading(async () => {
            try {
                // Get customfields
                const { publicKey } = settings;

                const _publicKey = publicKey || uuid();

                await BitrixService.updateSettingsAsync(
                    _publicKey,
                    webhookUrl,
                    ownerId,
                    brokerId,
                    brokerName,
                    blipToken
                );

                const _settings = {
                    webhookUrl,
                    publicKey: _publicKey
                };

                // Set Webhook
                await ApplicationService.addAnalyticsWebhooks(
                    getWebhookUrl(_publicKey)
                );

                await ApplicationService.setBucketDocument(
                    B24_SETTINGS_BUCKET_DOCUMENT_ID,
                    B24_SETTINGS_TYPE,
                    _settings
                );

                await dispatch(
                    ApplicationActions.setBucketDocument(
                        B24_SETTINGS_BUCKET_DOCUMENT_ID,
                        _settings
                    )
                );
            } catch (err) {
                console.warn('Bucket doesnt exists');
            }
        });
    };

    const displayVariables = settings.webhookUrl ? 'block' : 'none';

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={'Bitrix'}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, BLANK)}
            />
            <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div className="w-50 mt3 mr2 center">
                    <Settings
                        settings={settings || {}}
                        onSave={(webhookUrl) => setSettings(webhookUrl)}
                    />
                </div>
                <br />
                <div
                    className="w-50 mt3 mr2 center"
                    style={{
                        display: `${displayVariables}`
                    }}
                >
                    <Variables customFields={settings.fields} />
                </div>
                <br />
                <div
                    className="w-50 mt3 mr2 center"
                    style={{
                        display: `${displayVariables}`
                    }}
                ></div>
            </div>
        </div>
    );
};

Home.propTypes = {
    appInfo: PropTypes.object,
    settings: PropTypes.object,
    dispatch: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        appInfo: state.application.appInfo,
        language: state.common.language,
        buckets: state.application.buckets,
        settings: state.application.buckets[
            B24_SETTINGS_BUCKET_DOCUMENT_ID
        ] || {
            webhookUrl: '',
            publicKey: ''
        },
        ...props
    };
};

export default ConnectTo(mapStateToProps)(Home);
