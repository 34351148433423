/* eslint-disable */
import React, { useState } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Card from '../../components/Card';

import Header from './components/Header';
import { showToast } from '../../services/common-service';
import * as ApplicationService from '../../services/application-service';
import wwApi from '../../factory/ww-api';
import * as BlipPortalToastTypes from '../../config/constants/blip-portal-toast-types';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/34e851fc39fb4197901fe5439e412a98';

const Home = () => {
    const [zendeskSubdomain, setZendeskSubdomain] = React.useState('');
    const [zendeskToken, setZendeskToken] = React.useState('');
    const [zendeskEmail, setZendeskEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        location.href = 'https://plugins-v2.whitewall.dev/plugins/zendesk.html'
    }, [])

    useState(async () => {
        const config = await ApplicationService.getBucketDocument('zendesk-config');
        if (config) {
            setZendeskSubdomain(config.zendeskSubdomain);
            setZendeskToken(config.zendeskToken);
            setZendeskEmail(config.zendeskEmail);
        }
    }, [])

    const handleSave = async () => {
        setLoading(true);

        const { shortName, accessKey, tenantId } = await ApplicationService.getApplicationData();
        try {
            await ApplicationService.setBucketDocument('zendesk-config', 'application/json', {
                zendeskSubdomain,
                zendeskToken,
                zendeskEmail,
            })

            await wwApi.post('/zendesk/install', {
                subdomain: zendeskSubdomain,
                username: zendeskEmail,
                token: zendeskToken,
                botIdentity: shortName,
                botAccessKey: accessKey,
                tenantId
            })

            showToast({
                type: 'success',
                message: 'Salvo com sucesso'
            });
        } catch (err) {
            console.error(err);
            showToast({
                type: BlipPortalToastTypes.danger,
                message:
                    'Não foi instalar o plugin no Zendesk. Por favor, tente novamente mais tarde.'
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={'Zendesk'}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, BLANK)}
            />
            <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div className="w-50 mt3 mr2 center">
                    <Card>
                        <div className="flex flex-column">
                            <Input
                                name="zendesk-subdomain"
                                label="Subdominio do zendesk"
                                placeholder="blip365"
                                value={zendeskSubdomain}
                                onChange={(e) =>
                                    setZendeskSubdomain(e.target.value)
                                }
                            />
                            <p className="f6 mt2 dark-blue">
                                Veja como encontrar:&nbsp;
                                <a
                                    target="_blank"
                                    href="https://support.zendesk.com/hc/pt-br/articles/4409381383578"
                                    rel="noreferrer"
                                >
                                    https://support.zendesk.com/hc/pt-br/articles/4409381383578
                                </a>
                                .
                            </p>
                            <Input
                                name="zendesk-username"
                                label="E-mail da conta de admin do zendesk"
                                type="email"
                                placeholder="email@domain.com"
                                value={zendeskEmail}
                                onChange={(e) =>
                                    setZendeskEmail(e.target.value)
                                }
                            />
                            <br />
                            <Input
                                name="zendesk-api-key"
                                label="Chave de API da conta de admin do Zendesk"
                                placeholder="abc123abc123abc123abc123abc123abc123"
                                value={zendeskToken}
                                onChange={(e) =>
                                    setZendeskToken(e.target.value)
                                }
                            />
                            <p className="f6 mt2 dark-blue">
                                Veja como encontrar:&nbsp;
                                <a
                                    target="_blank"
                                    href="https://support.zendesk.com/hc/pt-br/articles/4408889192858"
                                    rel="noreferrer"
                                >
                                    https://support.zendesk.com/hc/pt-br/articles/4408889192858
                                </a>
                                .
                            </p>
                            <div className="flex justify-end">
                                <Button
                                    text={'Salvar'}
                                    loading={loading}
                                    disabled={!zendeskSubdomain || !zendeskToken || !zendeskEmail}
                                    onClick={handleSave}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Home;

