import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';

const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/933f0a086afc4d10aa0fbeef17f5a600';

const Settings = ({ settings, onSave }) => {
    // const { t } = useTranslation();
    const [url, setUrl] = useState(settings.url || '');
    const [key, setKey] = useState(settings.key || '');

    useEffect(() => {
        // on settings update
        setUrl(settings.url || '');
        setKey(settings.key || '');
    }, [settings]);

    return (
        <>
            <Card>
                <h4 className="f4 mt0 mb3">{`Configurações`}</h4>
                <p>
                    {`Preencha as informações abaixo de acordo com sua conta de desenvolvedor no Active Campaign e depois clique no botão 'Salvar e Sincronizar'`}
                </p>
                <a
                    className="f6 flex items-center blue no-underline underline-hover"
                    href="#0"
                    data-testid="exemple-one"
                    onClick={() => {
                        window.open(PLUGIN_HELP_PAGE_URL, BLANK);
                    }}
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {`Clique aqui para saber onde encontrar essas informações em sua conta do Active Campaign`}
                </a>
                <br />
                <div className="mb3">
                    <Input
                        name="url"
                        label="Url"
                        placeholder="ex.: https://ravpacheco1.api-us1.com"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </div>
                <div className="mb3">
                    <Input
                        type="password"
                        name="key"
                        label="Chave"
                        placeholder="ex.: 760645378b4d9f46fa5a8867d5d6d"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-center">
                    <div className="ml3">
                        <Button
                            text={'SALVAR E SINCRONIZAR'}
                            icon="save-disk"
                            variant="primary"
                            // disabled={!url || !key}
                            onClick={() => {
                                onSave(url, key);
                            }}
                            disabled={!url || !key}
                            data_testid="repository-link"
                        />
                    </div>
                </div>
                <br />
                <i className="gray">
                    {`Lembre-se de clicar no botão 'Salvar e Sincronizar' sempre que houver a criação ou alteração de algum campo ou de alguma tag de contato no Active Campaign`}
                </i>
            </Card>
        </>
    );
};

Settings.propTypes = {
    settings: PropTypes.object,
    onSave: PropTypes.func
};

export default Settings;
