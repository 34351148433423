import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Card from '../../../../components/Card';

const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/5051c8f944034f15bdf0ecd9f3413090';

const BLANK = '_blank';
const Variables = ({ customFields = [] }) => {
    // const { t } = useTranslation();

    const OptionalField = (
        <td className="pv3 bb b--black-20">
            <i>{'Opcional'}</i>
        </td>
    );

    return (
        <>
            <Card className="min-h-18">
                <div className="overflow-auto">
                    <h4 className="f4 mt0 mb3">{`Campos de Contatos`}</h4>
                    <p>
                        {`Durante a geração de um registro de evento, utilize os campos descritos abaixo.`}
                    </p>
                    <a
                        className="f6 flex items-center blue no-underline underline-hover"
                        href="#0"
                        data-testid="exemple-one"
                        onClick={() => {
                            window.open(PLUGIN_HELP_PAGE_URL, BLANK);
                        }}
                    >
                        <bds-icon name="file-txt-1" size="x-small" />
                        {`Clique aqui para ver um exemplo de como enviar dados do seus clientes para o Dynamics`}
                    </a>
                    <br />
                    <table className="f6 w-100 mw8 center">
                        <tbody className="lh-copy">
                            <tr>
                                <td className="pv3 b bb b--black-20">
                                    {'leadid'}
                                </td>
                                <td className="pv3 b bb b--black-20">
                                    {'*Obrigatório (formato Guid)'}
                                </td>
                            </tr>
                            {customFields.map((cf, i) => (
                                <tr key={`cf-${i}`}>
                                    <td className="pv3 bb b--black-20">
                                        {cf.title}
                                    </td>
                                    {OptionalField}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
};

Variables.propTypes = {
    customFields: PropTypes.array
};

export default Variables;
