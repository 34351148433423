import axios from 'axios';

import EnvSettings from '../config/index';

const HEADER_ACCEPT = 'Accept';
const TYPE_JSON = 'application/json';

const ApliClient = () => {
    const baseUrl = EnvSettings.ww_api.url;
    const api = axios.create({ baseURL: baseUrl });
    api.interceptors.request.use(async (config) => {
        const headerConfig = config;
        headerConfig.headers[HEADER_ACCEPT] = TYPE_JSON;
        return headerConfig;
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return api;
};
export default ApliClient();
