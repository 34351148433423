/* eslint-disable */
import React, { useEffect } from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import { withLoading } from '../../services/common-service';
import * as ApplicationService from '../../services/application-service';
import * as DeskContactEditorService from '../../services/desk-contact-editor-service';

import Header from './components/Header';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/e02771d26caa44ab81cdfb1610f2d11c';

const Home = () => {
    const [isLoggedIn, setIsLoggedIn] = React.useState(undefined);

    const fetchLoginInfo = () => {
        withLoading(async () => {
            try {
                const { shortName } = await ApplicationService.getApplicationData();
                const publicKey = await ApplicationService.getBucketDocument('dce-publickey');
                const loggedIn =
                    Boolean(publicKey) &&
                    (await DeskContactEditorService.isLoggedIn(publicKey, shortName));
                setIsLoggedIn(loggedIn);
            } catch (err) {
                console.error(err);
                setIsLoggedIn(false);
            }
        });
    };

    useEffect(() => {
        window.location.href = 'https://plugins-v2.whitewall.dev/plugins/deskcontacteditor.html';
    }, []);

    const [buttonText, setButtonText] = React.useState('Carregando');
    useEffect(() => {
        if (isLoggedIn) {
            setButtonText('Plugin já ativado');
        } else if (isLoggedIn === false) {
            setButtonText('Ativar plugin');
        }
    }, [isLoggedIn]);

    const onClickActivate = async () => {
        if (isLoggedIn !== false) {
            return;
        }

        withLoading(async () => {
            const { shortName, accessKey, tenantId } = await ApplicationService.getApplicationData();
            const { publicKey } = await DeskContactEditorService.updateSettings(shortName, accessKey, tenantId);
            await ApplicationService.setBucketDocument('dce-publickey', 'text/plain', publicKey);
            setIsLoggedIn(true);
        });
    };

    useEffect(() => {
        fetchLoginInfo();
    }, []);

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={'BLiP Desk Contact Editor'}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, BLANK)}
            />
            <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div className="w-50 mt3 mr2 center">
                    <Card>
                        <p className="f5 mt0 mb3">
                            Primeiro, ative o plugin no bot atual abaixo:
                        </p>

                        <Button
                            onClick={onClickActivate}
                            text={buttonText}
                            disabled={isLoggedIn !== false}
                        />
                        <br />

                        <p className="f5 mt0 mb3">
                            Após a ativação, instale a extensão no
                            navegador&nbsp;
                            <a
                                target="_blank"
                                href="https://chrome.google.com/webstore/detail/blip-desk-contact-editor/akfjcmbdebmamhmoldbkgomdbifedjnc"
                                rel="noreferrer"
                            >
                                clicando aqui
                            </a>
                            .
                        </p>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Home;
