import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as RoutesPath from '../config/constants/routes-path';

import BuilderVariablesPage from '../pages/BuilderVariables';
import Emojis from '../pages/Emojis';
import ExamplePage from '../pages/_Example';
import Home from '../pages/_Home';
import CheckoutPage from '../pages/Checkout';
import ActiveCampaignPage from '../pages/ActiveCampaign';
import ActiveCampaignPageWithouPaywall from '../pages/ActiveCampaign/HomeWithoutPaywall';
import DynamicsPage from '../pages/Dynamics';
import DynamicsPageWithouPaywall from '../pages/Dynamics/HomeWithoutPaywall';
import DeskContactEditor from '../pages/DeskContactEditor';
import DeskMarkUnread from '../pages/DeskMarkUnread';
import DeskMassTransfer from '../pages/DeskMassTransfer';
import DeskTicketTags from '../pages/DeskTicketTags';
import Zendesk from '../pages/Zendesk';
import GPT3 from '../pages/GPT3';
import FileUploader from '../pages/FileUploader';
import ODPage from '../pages/OuvidorDigital';
import BitrixPage from '../pages/Bitrix';
import ExactSpotter from '../pages/ExactSpotter';

import Analytics from './Analytics';

const Routes = () => (
    <BrowserRouter>
        <Analytics>
            <Switch>
                <Route
                    exact
                    path={RoutesPath.builderVariables.path}
                    component={BuilderVariablesPage}
                />
                <Route exact path={RoutesPath.home.path} component={Home} />
                <Route
                    exact
                    path={RoutesPath.example_page.path}
                    component={ExamplePage}
                />
                <Route exact path={RoutesPath.emojis.path} component={Emojis} />
                <Route
                    exact
                    path={RoutesPath.checkout.path}
                    component={CheckoutPage}
                />
                <Route
                    exact
                    path={RoutesPath.activeCampaign.path}
                    component={ActiveCampaignPage}
                />
                <Route
                    exact
                    path={RoutesPath.activeCampaign.pathNoPayWall}
                    component={ActiveCampaignPageWithouPaywall}
                />
                <Route
                    exact
                    path={RoutesPath.dynamics.path}
                    component={DynamicsPage}
                />
                <Route
                    exact
                    path={RoutesPath.dynamics.pathNoPayWall}
                    component={DynamicsPageWithouPaywall}
                />
                <Route
                    exact
                    path={RoutesPath.deskContactEditor.path}
                    component={DeskContactEditor}
                />
                <Route
                    exact
                    path={RoutesPath.deskMarkUnread.path}
                    component={DeskMarkUnread}
                />
                <Route
                    exact
                    path={RoutesPath.deskMassTransfer.path}
                    component={DeskMassTransfer}
                />
                <Route
                    exact
                    path={RoutesPath.deskTicketTags.path}
                    component={DeskTicketTags}
                />
                <Route
                    exact
                    path={RoutesPath.zendesk.path}
                    component={Zendesk}
                />
                <Route exact path={RoutesPath.gpt3.path} component={GPT3} />
                <Route exact path={RoutesPath.fileUploader.path} component={FileUploader} />
                <Route exact path={RoutesPath.od.path} component={ODPage} />
                <Route
                    exact
                    path={RoutesPath.bitrix.path}
                    component={BitrixPage}
                />
                <Route
                    exact
                    path={RoutesPath.exactSpotter.path}
                    component={ExactSpotter}
                />
            </Switch>
        </Analytics>
    </BrowserRouter>
);

export default Routes;
