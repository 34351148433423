import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConnectTo from '../../../../store/connect';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';
import InputEditable from '../../../../components/InputEditable';
import Button from '../../../../components/Button';

import { withLoading } from '../../../../services/common-service';
import * as ApplicationService from '../../../../services/application-service';
import * as ApplicationActions from '../../../../store/actions/application';

import { COLOR_RED_DELETE } from '../../../../config/constants/colors';

const ContextVariables = ({ appInfo, language, variables, dispatch }) => {
    const [identity, setIdentity] = useState('');
    const [identityNotFound, setIdentityNotFound] = useState(false);
    const contactVariables = variables[identity];

    useEffect(() => {
        // action on update of movies
    }, [identity, variables]);

    const setBuilderVariable = (variableName, variableValue) => {
        withLoading(async () => {
            await ApplicationService.setContactContextVariable(
                identity,
                variableName,
                variableValue
            );
        });
    };

    const deleteBuilderVariable = (variableName) => {
        withLoading(async () => {
            const newContactVariables = contactVariables.filter((v) => {
                return v.label !== variableName;
            });

            await ApplicationService.deleteContactContextVariable(
                identity,
                variableName
            );

            await dispatch(
                ApplicationActions.setContactContextVariables(
                    identity,
                    newContactVariables
                )
            );
        });
    };

    const getBuiderVariables = (contactIdentity) => {
        withLoading(async () => {
            let variableNames;
            try {
                variableNames =
                    await ApplicationService.getContactContextVariables(
                        contactIdentity
                    );
            } catch (err) {
                setIdentityNotFound(true);
            }

            const _variables = [];
            if (!!variableNames) {
                for (const v of variableNames.items) {
                    try {
                        const valueResponse =
                            // eslint-disable-next-line no-await-in-loop
                            await ApplicationService.getContactContextVariableValue(
                                contactIdentity,
                                v
                            );
                        _variables.push({
                            label: v,
                            value: valueResponse
                        });
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.log(`Variable ${v} not found`);
                    }
                }
            }
            await dispatch(
                ApplicationActions.setContactContextVariables(
                    contactIdentity,
                    _variables
                )
            );
        });
    };

    return (
        <div className="w-70 center mv3">
            <Card className="min-h-18">
                <div className="overflow-auto w-100">
                    <div className="fl w-80">
                        <Input
                            name="search"
                            placeholder="Informe o identificador do contato que deseja pesquisar"
                            value={identity}
                            onChange={(e) => {
                                setIdentity(e.target.value);
                            }}
                        />
                    </div>

                    <div className="fr w-15">
                        <Button
                            text="Pesquisar"
                            variant="ghost"
                            arrow={false}
                            disabled={false}
                            onClick={(e) => {
                                getBuiderVariables(identity);
                            }}
                        />
                    </div>
                </div>

                <div className="overflow-auto mt2">
                    <table className="f6 w-100 mw8 center">
                        <tbody className="lh-copy">
                            {contactVariables &&
                                contactVariables.length > 0 &&
                                contactVariables.map((v, index) => {
                                    return (
                                        <tr key={`variabe_${index}`}>
                                            <td className="pv3 b bb b--black-20">
                                                <div
                                                    className={
                                                        'fl pt2 pr2 shadow-hover'
                                                    }
                                                    onClick={() => {
                                                        deleteBuilderVariable(
                                                            v.label
                                                        );
                                                    }}
                                                >
                                                    <bds-icon
                                                        name="trash"
                                                        color={COLOR_RED_DELETE}
                                                    />
                                                </div>
                                                <p className={''}>{v.label}</p>
                                            </td>
                                            <td className="pv3 bb b--black-20 mw6">
                                                <InputEditable
                                                    name={`v_value_${v.label}`}
                                                    value={v.value}
                                                    expand={true}
                                                    size={'short'}
                                                    min_length={1}
                                                    onEditableSave={(e) => {
                                                        setBuilderVariable(
                                                            v.label,
                                                            e.detail.value
                                                        );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            {identityNotFound && (
                                <tr key={`variabe_empty`}>
                                    Não existem variáveis para esse contato.
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Card>
        </div>
    );
};

ContextVariables.propTypes = {
    appInfo: PropTypes.object,
    language: PropTypes.string,
    variables: PropTypes.object,
    onClick: PropTypes.func,
    dispatch: PropTypes.func
};

const mapStateToProps = (state, props) => ({
    appInfo: state.application.appInfo,
    language: state.common.language,
    variables: state.application.contextVariables,
    ...props
});

export default ConnectTo(mapStateToProps)(ContextVariables);
