import React from 'react';
import ConnectTo from '../../store/connect';
import Header from './components/Header';
import ContextVariables from './components/ContextVariables';

const PAGE_TITLE = 'Ouvidor Digital';
const PAGE_ICON = 'resources';
const PLUGIN_HELP_PAGE_URL = 'https://site.ouvidordigital.com.br/takeblip/';

const ODHome = () => {
    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={PAGE_TITLE}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, '_blank')}
            />
            <ContextVariables />
        </div>
    );
};

ODHome.propTypes = {};

const mapStateToProps = (state, props) => ({
    ...props
});

export default ConnectTo(mapStateToProps)(ODHome);
