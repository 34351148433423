import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Card from '../../../../components/Card';

import { LEAD_VARIABLES } from '../../constants';

const PLUGIN_HELP_PAGE_URL = 'https://www.loom.com/share/8aec1eef65894e3e8eff093cfe0f306e';

const BLANK = '_blank';

// Exact Spotter Variables

const Variables = () => {
    // const { t } = useTranslation();

    const OptionalField = (
        <td className="pv3 bb b--black-20">
            <i>{'Opcional'}</i>
        </td>
    );

    const RequiredField = (
        <td className="pv3 b bb b--black-20">{'*Obrigatório'}</td>
    );

    return (
        <>
            <Card className="min-h-18">
                <div className="overflow-auto">
                    <h4 className="f4 mt0 mb3">{`Campos de Leads`}</h4>
                    <div>
                        {`Para criar um novo lead no Exact Spotter siga os passos abaixo.`}
                        <ol>
                            <li>{`Crie uma ação do tipo 'Registro de evento' no builder com a categoria 'lead' e a action 'create'.`}</li>
                            <li>{`Adicione campos extras na ação de acordo com os nomes apresentados na tabela abaixo.`}</li>
                        </ol>
                    </div>
                    <a
                        className="f6 flex items-center blue no-underline underline-hover"
                        href="#0"
                        data-testid="exemple-one"
                        onClick={() => {
                            window.open(PLUGIN_HELP_PAGE_URL, BLANK);
                        }}
                    >
                        <bds-icon name="file-txt-1" size="x-small" />
                        {`Clique aqui para ver um exemplo de como enviar dados do seus contatos do Blip para o Exact Spotter`}
                    </a>
                    <br />
                    <table className="f6 w-100 mw8 center">
                        <tbody className="lh-copy">
                            {LEAD_VARIABLES.map((lV, i) => (
                                <tr key={`cf-${i}`}>
                                    <td className="pv3 bb b--black-20">
                                        {lV.key}
                                    </td>
                                    {lV.isRequired
                                        ? RequiredField
                                        : OptionalField}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
};

Variables.propTypes = {
    customFields: PropTypes.array
};

export default Variables;
