import ApliClient from '../factory/ww-api';

const updateSettingsAsync = async (
    publicKey,
    webhookUrl,
    ownerId,
    brokerId,
    brokerName,
    blipToken
) => {
    const api = ApliClient;
    const config = {
        headers: {
            Authorization: publicKey
        }
    };
    const { data } = await api.post(
        'bitrix/settings',
        {
            webhookUrl,
            publicKey,
            ownerId: ownerId || '',
            brokerId: brokerId || '',
            brokerName: brokerName || '',
            blipToken: blipToken || ''
        },
        config
    );
    return data;
};

export { updateSettingsAsync };
