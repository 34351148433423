/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Card from '../../components/Card';
import * as ApplicationService from '../../services/application-service';
import { showToast, withLoading } from '../../services/common-service';
import * as BlipPortalToastTypes from '../../config/constants/blip-portal-toast-types';
import './style.scss';

import Header from './components/Header';
import Button from '../../components/Button';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/56681b6eb4cf4738a5ff278eed9443dd';

const Home = () => {
    const [media, setMedia] = useState(null);

    const uploadFile = async (e) => {
        withLoading(async () => {
            try {
                const file = e.target.files[0]

                if (file) {
                    await ApplicationService.setOwnerConfiguration('postmaster@media.msging.net', { PrivateMediaUpload: 'False' })
                    const uploadUrl = await ApplicationService.getMediaUploadUrl()

                    const result = await fetch(uploadUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': file.type
                        },
                        body: file
                    })

                    const { mediaUri } = await result.json()
                    setMedia(mediaUri)

                    showToast({
                        type: BlipPortalToastTypes.success,
                        message: 'Arquivo enviado com sucesso'
                    })
                }
            } catch (err) {
                console.error(err);
                showToast({
                    type: BlipPortalToastTypes.danger,
                    message:
                        'Não foi fazer upload. Por favor, tente novamente mais tarde.'
                });
            }
        })
    }

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={'File Uploader'}
                icon={PAGE_ICON}
                onClick={() => window.open(PLUGIN_HELP_PAGE_URL, BLANK)}
            />
            <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <div className="w-50 mt3 mr2 center">
                    <Card>
                        <div className="flex flex-column justify-center">
                            <div className="file-upload">
                                <div className="image-upload-wrap">
                                    <input className="file-upload-input" type='file' onChange={uploadFile} />
                                    <div className="drag-text">
                                        <h3>Drag and drop a file or select</h3>
                                    </div>
                                </div>
                            </div>
                            {media && (
                                <div className="flex items-center justify-between">
                                    <p>
                                        <strong>URL do item carregado: </strong> {media.substr(0, 50)}...
                                    </p>
                                    <Button onClick={async () => {
                                        const textarea = document.createElement('textarea');
                                        textarea.textContent = media;
                                        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
                                        document.body.appendChild(textarea);
                                        textarea.select();
                                        document.execCommand('copy');
                                        textarea.style.display = 'none';
                                        showToast({
                                            type: BlipPortalToastTypes.success,
                                            message: 'URL copiada com sucesso'
                                        })
                                    }}>Copiar URL</Button>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Home;
