/* eslint-disable array-callback-return */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import ConnectTo from '../../../../store/connect';
import Card from '../../../../components/Card';
import EmptyResult from '../../../../components/EmptyResult';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import { withLoading, showToast } from '../../../../services/common-service';

import EmojiList from '../../../../config/constants/custom-emojis.json';
import * as BlipPortalToastTypes from '../../../../config/constants/blip-portal-toast-types';

const generateEmojiGroups = (emojis) => {
    const EmojiGroups = {};
    emojis.map((e) => {
        if (!EmojiGroups[e.group]) {
            EmojiGroups[e.group] = e.pt.group;
        }
    });
    return EmojiGroups;
};

const copyToClipboard = (text) => {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData('Text', text);
    }
    if (
        document.queryCommandSupported &&
        document.queryCommandSupported('copy')
    ) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy'); // Security exception may be thrown by some browsers.
            showToast({
                type: BlipPortalToastTypes.success,
                message: 'Emoji copiado com sucesso'
            });
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.warn('Copy to clipboard failed.', ex);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
};

const EmojisCard = ({ appInfo, language, variables, dispatch }) => {
    const [query, setQuery] = useState('');
    const [emojis, setEmojis] = useState(EmojiList);
    const [emojiGroups, setEmojiGroups] = useState(
        generateEmojiGroups(EmojiList)
    );

    useEffect(() => {
        // action on update of movies
    }, [query, variables]);

    const contains = (value, searchValue) => {
        return (
            value.toString().toLowerCase().indexOf(searchValue.toString()) !==
            -1
        );
    };

    const onSearch = (value) => {
        withLoading(async () => {
            const newEmojis = EmojiList.filter((e) => {
                let neutralValue = '';
                if (
                    value.length >= 2 &&
                    (value[value.length - 1] === 'a' ||
                        value[value.length - 1] === 'o')
                ) {
                    neutralValue = value.substring(0, value.length - 1);
                } else {
                    neutralValue = value;
                }

                return (
                    contains(e.group, neutralValue) ||
                    contains(e.name, neutralValue) ||
                    contains(e.category, neutralValue) ||
                    contains(e.pt.group, neutralValue) ||
                    contains(e.pt.name, neutralValue) ||
                    contains(e.pt.category, neutralValue)
                );
            });
            setEmojis(newEmojis);
            setEmojiGroups(generateEmojiGroups(newEmojis));
        });
    };

    return (
        <div className="w-70 center mv3">
            <Card className="min-h-18">
                <div className="overflow-auto w-100">
                    <div className="fl w-80">
                        <Input
                            name="search"
                            placeholder="Informe o nome ou o identificador do emoji que deseja pesquisar"
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value);
                            }}
                        />
                    </div>

                    <div className="fr w-15">
                        <Button
                            text="Pesquisar"
                            variant="ghost"
                            arrow={false}
                            disabled={false}
                            onClick={(e) => {
                                onSearch(query);
                            }}
                        />
                    </div>
                </div>
                {emojis.length === 0 ? (
                    <EmptyResult title={'Ops...'}>
                        <>
                            <div className="flex flex-column items-center justify-center">
                                <p className="f5 bp-c-medium-cloud mt2 mw6 tc">
                                    Nenhum emoji encontrado com essa pesquisa
                                </p>
                                <Button
                                    text="Limpar"
                                    variant="ghost"
                                    arrow={false}
                                    disabled={false}
                                    onClick={(e) => {
                                        setQuery('');
                                        onSearch('');
                                    }}
                                />
                            </div>
                        </>
                    </EmptyResult>
                ) : (
                    Object.keys(emojiGroups).map((group, index) => (
                        <Fragment key={`group-emoji-${index}`}>
                            <h4>{emojiGroups[group]}</h4>
                            <div className="overflow-auto mt2">
                                {emojis.map((emoji, index2) => {
                                    if (emoji.group === group) {
                                        return (
                                            <div
                                                key={`emoji-${index2}`}
                                                className="di m2"
                                                style={{
                                                    fontSize: '35px',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    copyToClipboard(emoji.char);
                                                }}
                                            >
                                                {emoji.char}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </Fragment>
                    ))
                )}
            </Card>
        </div>
    );
};

EmojisCard.propTypes = {
    appInfo: PropTypes.object,
    language: PropTypes.string,
    variables: PropTypes.object,
    onClick: PropTypes.func,
    dispatch: PropTypes.func
};

const mapStateToProps = (state, props) => ({
    appInfo: state.application.appInfo,
    language: state.common.language,
    variables: state.application.contextVariables,
    ...props
});

export default ConnectTo(mapStateToProps)(EmojisCard);
