import ApliClient from '../factory/ww-api';

const updateSettingsAsync = async (publicKey, clientUrl, clientKey) => {
    const api = ApliClient;
    const config = {
        headers: {
            Authorization: publicKey
        }
    };
    const { data } = await api.post(
        'activeCampaign/settings',
        {
            url: clientUrl,
            key: clientKey
        },
        config
    );
    return data;
};

export { updateSettingsAsync };
