import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';

const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL = 'https://www.youtube.com/watch?v=40M2_geA7Mw';

const Settings = ({ settings, onSave }) => {
    // const { t } = useTranslation();
    const [tenantId, setTenantId] = useState(settings.tenantId || '');
    const [host, setHost] = useState(settings.host || '');
    const [clientId, setClientId] = useState(settings.clientId || '');
    const [userName, setUserName] = useState(settings.userName || '');
    const [password, setPassword] = useState(settings.password || '');

    useEffect(() => {
        // on settings update

        setTenantId(settings.tenantId || '');
        setHost(settings.host || '');
        setClientId(settings.clientId || '');
        setUserName(settings.userName || '');
        setPassword(settings.password || '');
    }, [settings]);

    return (
        <>
            <Card>
                <h4 className="f4 mt0 mb3">{`Configurações`}</h4>
                <p>
                    {`Preencha as informações abaixo de acordo com sua instância Dynamics e sua conta no portal Azure.`}
                    <i className="gray">
                        {`Após preencher as informações clique no botão 'Salvar e Sincronizar'`}
                    </i>
                </p>
                <a
                    className="f6 flex items-center blue no-underline underline-hover"
                    href="#0"
                    data-testid="exemple-one"
                    onClick={() => {
                        window.open(PLUGIN_HELP_PAGE_URL, BLANK);
                    }}
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {`Clique aqui para saber onde encontrar essas informações em sua instância Dynamics`}
                </a>
                <br />
                <div className="mb3">
                    <Input
                        name="tenantId"
                        label="Azure TenantId"
                        placeholder="ex.: 862e087d-acff-4986-a466-df1fb16dc3d3"
                        value={tenantId}
                        onChange={(e) => setTenantId(e.target.value)}
                    />
                </div>

                <div className="mb3">
                    <Input
                        name="host"
                        label="Dynamics Host"
                        placeholder="ex.: org8bb7h2f.crm2.dynamics.com"
                        value={host}
                        onChange={(e) => setHost(e.target.value)}
                    />
                </div>

                <div className="mb3">
                    <Input
                        name="clientId"
                        label="Dynamics ClientId"
                        placeholder="ex.: 438fde01-e471-47ce-f376-ba27647c4444"
                        value={clientId}
                        onChange={(e) => setClientId(e.target.value)}
                    />
                </div>

                <div className="mb3">
                    <Input
                        name="userName"
                        label="Dynamics UserName"
                        placeholder="ex.: 438fde01-e471-47ce-f376-ba27647c4444"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </div>

                <div className="mb3">
                    <Input
                        type={'password'}
                        name="password"
                        label="Dynamics Password"
                        placeholder="ex.: 2-xCDdL.UaT127Gy9zz3"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="flex items-center justify-center">
                    <div className="ml3">
                        <Button
                            text={'SALVAR E SINCRONIZAR'}
                            icon="save-disk"
                            variant="primary"
                            onClick={() => {
                                onSave(
                                    tenantId,
                                    host,
                                    clientId,
                                    userName,
                                    password
                                );
                            }}
                            disabled={
                                !tenantId ||
                                !host ||
                                !clientId ||
                                !userName ||
                                !password
                            }
                            data_testid="repository-link"
                        />
                    </div>
                </div>
            </Card>
        </>
    );
};

Settings.propTypes = {
    settings: PropTypes.object,
    onSave: PropTypes.func
};

export default Settings;
