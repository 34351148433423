import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';

const BLANK = '_blank';
const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/78ab758aa57a47d687b60fd9548f0914';

const Settings = ({ settings, onSave }) => {
    // const { t } = useTranslation();
    const [webhookUrl, setWebhookUrl] = useState(settings.webhookUrl || '');

    useEffect(() => {
        // on settings update
        setWebhookUrl(settings.webhookUrl || '');
    }, [settings]);

    return (
        <>
            <Card>
                <h4 className="f4 mt0 mb3">{`Configurações`}</h4>
                <p>
                    {`Preencha as informações abaixo de acordo com sua conta Bitrix e depois clique no botão 'Salvar'`}
                </p>
                <a
                    className="f6 flex items-center blue no-underline underline-hover"
                    href="#0"
                    data-testid="exemple-one"
                    onClick={() => {
                        window.open(PLUGIN_HELP_PAGE_URL, BLANK);
                    }}
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {`Clique aqui para saber onde encontrar essas informações em sua conta do Bitrix`}
                </a>
                <br />
                <div className="mb3">
                    <Input
                        name="webhookUrl"
                        label="Webhook Url (Inbound)"
                        placeholder="ex.: https://whitewall.bitrix24.com/rest/42/zb9bctpe0z2qt2xa/"
                        value={webhookUrl}
                        onChange={(e) => setWebhookUrl(e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-center">
                    <div className="ml3">
                        <Button
                            text={'SALVAR'}
                            icon="save-disk"
                            variant="primary"
                            // disabled={!url || !key}
                            onClick={() => {
                                onSave(webhookUrl);
                            }}
                            disabled={!webhookUrl}
                            data_testid="repository-link"
                        />
                    </div>
                </div>
                <br />
            </Card>
        </>
    );
};

Settings.propTypes = {
    settings: PropTypes.object,
    onSave: PropTypes.func
};

export default Settings;
