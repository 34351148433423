import * as action_types from '../../config/constants/application-actions';

const setApplication = (payload) => ({
    type: action_types.set_application,
    payload
});

const setConfiguration = (payload) => ({
    type: action_types.set_configuration,
    payload
});

const setContactContextVariables = (contactIdentity, variables) => ({
    type: action_types.set_context_variables,
    payload: {
        contactIdentity,
        variables
    }
});

const setBucketDocument = (bucketId, resource) => ({
    type: action_types.set_bucket_document,
    payload: {
        bucketId,
        resource
    }
});

export {
    setApplication,
    setConfiguration,
    setContactContextVariables,
    setBucketDocument
};
