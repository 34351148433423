import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Card from '../../../../components/Card';

const PLUGIN_HELP_PAGE_URL =
    'https://www.loom.com/share/933f0a086afc4d10aa0fbeef17f5a600';

const BLANK = '_blank';
const Variables = ({ customFields = [] }) => {
    // const { t } = useTranslation();

    const OptionalField = (
        <td className="pv3 bb b--black-20">
            <i>{'Opcional'}</i>
        </td>
    );

    return (
        <>
            <Card className="min-h-18">
                <div className="overflow-auto">
                    <h4 className="f4 mt0 mb3">{`Campos de Contatos`}</h4>
                    <div>
                        {`Para atualizar um campo de contato no Active Campaign siga os passos abaixo.`}
                        <ol>
                            <li>{`Crie uma ação do tipo 'Registro de evento' no builder com a categoria 'lead' e a action 'create'.`}</li>
                            <li>{`Adicione campos extras na ação de acordo com os nomes apresentados na tabela abaixo.`}</li>
                            <li>
                                <b>
                                    {`A ação criada deve possuir o campo extras 'email' contendo o email do contato que terá seus campos atualizados.`}
                                </b>
                            </li>
                        </ol>
                    </div>
                    <a
                        className="f6 flex items-center blue no-underline underline-hover"
                        href="#0"
                        data-testid="exemple-one"
                        onClick={() => {
                            window.open(PLUGIN_HELP_PAGE_URL, BLANK);
                        }}
                    >
                        <bds-icon name="file-txt-1" size="x-small" />
                        {`Clique aqui para ver um exemplo de como enviar dados do seus clientes para o Active Campaign`}
                    </a>
                    <br />
                    <table className="f6 w-100 mw8 center">
                        <tbody className="lh-copy">
                            <tr>
                                <td className="pv3 b bb b--black-20">
                                    {'email'}
                                </td>
                                <td className="pv3 b bb b--black-20">
                                    {'*Obrigatório'}
                                </td>
                            </tr>
                            <tr>
                                <td className="pv3 bb b--black-20">
                                    {'firstName'}
                                </td>
                                {OptionalField}
                            </tr>
                            <tr>
                                <td className="pv3 bb b--black-20">
                                    {'lastName'}
                                </td>
                                {OptionalField}
                            </tr>
                            <tr>
                                <td className="pv3 bb b--black-20">
                                    {'phone'}
                                </td>
                                {OptionalField}
                            </tr>
                            {customFields.map((cf, i) => (
                                <tr key={`cf-${i}`}>
                                    <td className="pv3 bb b--black-20">
                                        {cf.title}
                                    </td>
                                    {OptionalField}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
};

Variables.propTypes = {
    customFields: PropTypes.array
};

export default Variables;
